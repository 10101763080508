import { APPLY_COUPON, COUPON_ERROR , COUPON_APPLIED} from "./actionTypes";

const initialState = {
	coupon: [],
	coupon_error: "",
	coupon_applied: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case APPLY_COUPON:
			return { ...state, coupon: action.payload };
		case COUPON_APPLIED:
		 return { ...state, coupon_applied: action.payload };
		case COUPON_ERROR:
			return { ...state, coupon_error: action.payload };

		default:
			return state;
	}
}
