import React, { Component } from "react";
import Axios from "axios";
import { getSettings } from "../../services/settings/actions";
import { connect } from "react-redux";
import { getSingleLanguageData, getAllLanguages } from "../../services/languages/actions";
import { logoutUser } from "../../services/user/actions";
import {
	getRestaurantInfoSlug,
	getRestaurantLoadData
} from "../../services/items/actions";
import generateManifest from '../../generateManifest';
class CheckVersion extends Component {
	state = {
		updating: false,
	};
  __callAppSettings() { 
		 this.props.getSettings();
	setTimeout(() => {
		this.props.getRestaurantInfoSlug();
			
		this.props.getRestaurantLoadData();
	}, 2000);
		 
	}
	componentDidMount() {
		/*localStorage.setItem("storeColor", 'red');
		localStorage.setItem("storeLogo", '');
		localStorage.setItem("updatingMessage", '');
		localStorage.setItem("activeRestaurant", '');*/
		localStorage.setItem("footerCart", 'Cart');
		localStorage.setItem("footerFavourite", 'Favourite');
		localStorage.setItem("footerAccount", 'Account');
		localStorage.setItem("deliveryFooterAccount", 'Account');
		localStorage.setItem("footerAlerts", 'Alerts');
		localStorage.setItem("footerHome", 'Home');
		localStorage.setItem("footerNearme", 'Near Me');
		localStorage.setItem("footerExplore", 'Explore');
		/*localStorage.setItem("cartColorBg", 'red');
		localStorage.setItem("cartColorText", 'white');
		localStorage.setItem("currency_code", 'USD');
		localStorage.setItem("currency_symbol", '$');*/
		Axios.get(process.env.PUBLIC_URL+"/version.json").then((response) => {
			//getting new data from version.json file
			const forceNewSettingsServerVersion = response.data.forceNewSettingsVersion;
			const forceCacheClearServerVersion = response.data.forceCacheClearVersion;
			const forceLogoutAllCustomers = response.data.forceLogoutAllCustomers;
			//logging all data
			// console.log("Client Settings Version: ", localStorage.getItem("forceNewSettingsVersion"));
			// console.log("Server Settings Version: ", forceNewSettingsServerVersion);
			// console.log("Client Cache Version: ", localStorage.getItem("forceCacheClearVersion"));
			// console.log("Server Cache Version: ", forceCacheClearServerVersion);

			//firstime user donot have settings so set setting... and donot load updating section
			if (
				localStorage.getItem("forceNewSettingsVersion") === null &&
				localStorage.getItem("forceCacheClearVersion") === null
			) {
				localStorage.setItem("forceNewSettingsVersion", forceNewSettingsServerVersion);
				localStorage.setItem("forceCacheClearVersion", forceCacheClearServerVersion);
			} else {
				//settings are already set so old user so call update method if settings changed
				if (localStorage.getItem("forceNewSettingsVersion") !== forceNewSettingsServerVersion) {
					console.warn("Getting New Settings");
					//console.log("Getting New Settings");
					this.props.getSettings();
					localStorage.setItem("forceNewSettingsVersion", forceNewSettingsServerVersion);
				}

				if (localStorage.getItem("forceCacheClearVersion") !== forceCacheClearServerVersion) {
					const clearLocalStorage = () => {
						return new Promise((resolve) => {
							console.warn("Clear Local Storage");
							// take some backup
							 let geoLocation = localStorage.getItem("geoLocation");
							 let userSetAddress = localStorage.getItem("userSetAddress");
							 let storeColor = localStorage.getItem("storeColor");
							 let storeLogo = localStorage.getItem("storeLogo");
							 let updatingMessage = localStorage.getItem("updatingMessage");
							let userPreferedLanguage = localStorage.getItem("userPreferedLanguage");

							let activeRestaurant =
								localStorage.getItem("activeRestaurant") !== null
									? localStorage.getItem("activeRestaurant")
									: 1;
							//clear
							 localStorage.clear();

							//then push the backup again
							if (geoLocation !== null) {
								localStorage.setItem("geoLocation", geoLocation);
							}
							if (userSetAddress !== null) {
								localStorage.setItem("userSetAddress", userSetAddress);
							}
							// localStorage.setItem("storeColor", storeColor);
							// localStorage.setItem("storeLogo", storeLogo);
							// localStorage.setItem("updatingMessage", updatingMessage);
							// localStorage.setItem("activeRestaurant", activeRestaurant);
							this.props.getSettings();
							if (userPreferedLanguage !== null) {
								localStorage.setItem("userPreferedLanguage", userPreferedLanguage);
							}

							resolve("Completed clearLocalStorage");
						});
					};

					const clearCacheStorage = async() => {
						return await new Promise((resolve) => {
							console.warn("Clear Cache Storage");
							 if(navigator.serviceWorker !== undefined && navigator.serviceWorker !== null){
							   navigator.serviceWorker.getRegistrations().then(function(registrations) {
						
									console.log("registration ",registrations);
									for (let registration of registrations) {
										registration.unregister();
									}
								});
								if (caches) {
								
									// console.log("CACHES:", caches);
									caches.keys().then(function(names) {
										for (let name of names) caches.delete(name);
									});
								}
							}
								resolve("Completed clearCacheStorage");
						//	}
							
						});
					};

					const fetchSettingsAndLanguage = () => {
						return new Promise((resolve) => {
							console.warn("Fetch Settings and Translations");
							this.props.getSettings();
							if (localStorage.getItem("userPreferedLanguage") !== null) {
								this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
								resolve("Completed fetchSettingsAndLanguage");
							} else {
								this.props.getAllLanguages().then((languages) => {
									console.log(languages);
									console.log("Fetching Translation Data...");
									const id = languages.payload.filter((lang) => lang.is_default === 1)[0].id;
									this.props.getSingleLanguageData(id).then(() => {
										resolve("Completed fetchSettingsAndLanguage");
									});
								});
							}
						});
					};

					const updateClientVersion = () => {
						return new Promise((resolve) => {
							console.warn("Update Client Version");
							localStorage.setItem("forceNewSettingsVersion", forceNewSettingsServerVersion);
							localStorage.setItem("forceCacheClearVersion", forceCacheClearServerVersion);
							resolve("Completed updateClientVersion");
						});
					};

					const reloadBrowser = () => {
						return new Promise((resolve) => {
							window.location.reload(true);
							resolve("Completed reloadBrowser");
							this.setState({ updating: false });
							// /*setTimeout(() => {
							// 	this.setState({ updating: false }, () => {
							// 		window.location.reload(true);
							// 		resolve("Completed reloadBrowser");
							// 	});
							// }, 3 * 1000);*/
						});
					};

					async function doProcess() {
						await clearLocalStorage();
						await clearCacheStorage();
						await fetchSettingsAndLanguage();
						await updateClientVersion();
						await reloadBrowser();
					}
					
				   // Promise.all([clearLocalStorage(), clearCacheStorage(), updateClientVersion(), reloadBrowser()]);

				  doProcess();
				  this.setState({ updating: true });
				  	this.setState({ updating: false }, () => {
							}, 2 * 1000);
				}
				// if (localStorage.getItem("forceLogoutAllCustomers") !== null) {
				// 	if (localStorage.getItem("forceLogoutAllCustomers") !== forceLogoutAllCustomers) {
				// 		console.log("Logged out");
				// 		this.props.logoutUser();
				// 		localStorage.setItem("forceLogoutAllCustomers", forceLogoutAllCustomers);
				// 	}
				// } else {
				// 	localStorage.setItem("forceLogoutAllCustomers", forceLogoutAllCustomers);
				// }
			}
			this.props.getRestaurantInfoSlug();
			
		    this.props.getRestaurantLoadData();
		});
	}
	render() {
		const { updating } = this.state;
		return (
			<React.Fragment>
				{updating && (
					<React.Fragment>
						{/*<div className="update-full-notification">
							<span className="spin-load" />
						</div>
						<div className="update-full-notification" style={{ zIndex: 9999999999 }}>
							<h1 className="d-flex" style={{ marginTop: "8rem" }}>
								{localStorage.getItem("updatingMessage")}
							</h1>
						</div>*/}
						<img src={localStorage.getItem("restaurantSplashScreen")} />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

// export default CheckVersion;
const mapStateToProps = (state) => ({
	settings: state.settings.settings,
});

export default connect(
	mapStateToProps,
	{ getSettings, getSingleLanguageData, getAllLanguages, logoutUser,
		getRestaurantInfoSlug, getRestaurantLoadData }
)(CheckVersion);
